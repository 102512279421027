import '../css/news.css';

import arrow from '../assets/icons/arrow.svg';
import doc from '../assets/documents/doc_beiratas_2023.pdf';

import ArrowButton from './ArrowButton';
import { database } from '../firebase';
import { onValue, ref } from 'firebase/database';
import { useEffect, useState } from 'react';
import NavLink from "./NavLink";

const News = (props) => {

    let data = props.data;

    const dbRef = ref(database, '/new_news/');
    const [news, setNews] = useState([]);
    const [largeNewsPopup, setLargeNewsPopup] = useState(0);
    const [lnTitle, setLnTitle] = useState("");
    const [lnDate, setLnDate] = useState("");
    const [lnContent, setLnContent] = useState("");

    useEffect(() => {
        readData();
    }, []);

    function readData() {
        onValue(dbRef, (snapshot) => {
            if (snapshot.exists) {
                let news_data = Object.values(snapshot.val());

                if (news_data.length > 1) {
                    for (let i = 0; i < news_data.length; i++) {
                        if (news_data[i].id === 0) {
                            news_data.splice(i, 1);
                        }
                    }
                }

                setNews(news_data);
            }
        });
    }

    function intToDate(num) {

        if (num === 0) {
            num = Date.now();
        }
        
        let date = new Date(num);
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        const year = date.getFullYear();

        return `${year}.${month}.${day}.`;
    }

    function openLargeNewsPopup(title, date, content) {
        setLargeNewsPopup(1);

        setLnTitle(title);
        setLnDate(date);
        setLnContent(content);
    }

    function closeLargeNewsPopup() {
        setLargeNewsPopup(0);

        setLnTitle("");
        setLnDate("");
        setLnContent("");
    }

    return (
        <>
            <>
                {
                    data.show ? 
                    <div className="news-section" id="news-section">
                        <p className="f36">Beiratás</p>
                        <p className="f21">{data.text}</p>
                        <ArrowButton text="DOKUMENTUM" icon={arrow} doc={data.doc} />
                    </div> : ''
                }
            </>
            <>
                {
                    largeNewsPopup === 0 ? "" :
                    <div className='ln-popup'>
                        <div className='ln-popup-title'>
                            <p className='f16-p'>{lnTitle}</p>
                            <p className='f14'>{intToDate(lnDate)}</p>
                        </div>
                        <div className='ln-popup-content'>
                            <p className='f16-p'>{lnContent}</p>
                        </div>
                        <div className='ln-popup-btn' onClick={() => {
                            closeLargeNewsPopup();
                        }}>
                            <NavLink title='BEZÁRÁS' class={'nb-link'} size={30} />
                        </div>
                    </div>
                }
            </>
            <div className='news'>
                <div className='g-title'>
                    <p className='f36'>HÍREINK</p>
                    <div className='gt-num'>
                        <p className='f48-mono noselect'>01/05</p>
                    </div>
                </div>
                <div className='news-cards'>
                    {
                        Object.values(news).map((news_card, index) => {
                            return ( index <= 3 ?
                                <div className='news-card' key={index} onClick={() => {
                                    openLargeNewsPopup(news_card.title, news_card.date, news_card.content);
                                }}>
                                    <div className='news-card-title'>
                                        <p className='f16-p'>{news_card.title}</p>
                                        <p className='f14'>{intToDate(news_card.date)}</p>
                                    </div>
                                    <div className='news-card-content'>
                                        <p className='f16-p'>{news_card.content}</p>
                                    </div>
                                </div>
                            : "");
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default News