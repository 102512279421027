import '../css/contact.css';
import arrow from '../assets/icons/arrow_right.svg';
import mail from '../assets/icons/envelope-solid.svg';
import location from '../assets/icons/location-dot-solid.svg';
import phone from '../assets/icons/phone-solid.svg';
import facebook from '../assets/icons/facebook-f-brands.svg';
import ContactIcon from './ContactIcon';

import { useEffect } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import ArrowButton from './ArrowButton';

const Contact = () => {

    var map = null;

    useEffect(() => {
        var myIcon = L.divIcon({className: 'my-div-icon'});

        if (map !== undefined && map !== null) { map.remove(); }//added

        map = L.map( 'schoolmap', { //alterated
            center: [46.07410, 18.21259],
            minZoom: 2,
            zoom: 18
        });

        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
            attribution: "7624 Pécs, Nagy Jenő utca 38."
        }).addTo(map);

        L.marker([46.07410, 18.21259], {icon: myIcon}).addTo(map);
    }, [])

    return (
        <div className='contact' id='contact'>
            <div className='c-title'>
                <div className='ct-container'>
                    <p className='f36'>KAPCSOLAT</p>
                    <div className='ctc-container'>
                        <ContactIcon img={location} alt='Hely' href='https://www.google.com/maps/place/P%C3%A9csi+Nagy+Jen%C5%91+utcai+%C3%93voda/@46.0741397,18.2124569,18.58z/data=!4m13!1m7!3m6!1s0x4742b111ea3252e3:0x400c4290c1e1200!2zUMOpY3M!3b1!8m2!3d46.0727345!4d18.232266!3m4!1s0x4742b196eb540ddf:0x84cac5ea0f39f331!8m2!3d46.0740463!4d18.2126296' info='7624 Pécs, Nagy Jenő utca 38.' newPage='1'/>
                        <ContactIcon img={mail} alt='Email' href='mailto:nagyovi@nyugatiovoda.hu' info='nagyovi@nyugatiovoda.hu' />
                        <ContactIcon img={phone} alt='Telefon' href='tel:+36304839971' info='+36 30 483 9971' />
                        <ContactIcon img={phone} alt='Telefon' href='tel:+36307794676' info='+36 30 779 4676' />
                        <ContactIcon img={phone} alt='Telefon' href='tel:+3672310014' info='+36 72 310 014' />
                        <ContactIcon img={facebook} alt='Facebook' href='https://www.facebook.com/people/Nagy-Jen%C5%91-Utcai-%C3%93voda-P%C3%A9cs/100066774156499/' info='Facebook' newPage='1'/>
                    </div>
                </div>
                <p className='f48-mono noselect'>05/05</p>
            </div>
            <div className='news' style={{display: 'none'}}>
                <p className='f21'>Felújítás miatt óvodánk ideiglenes címe:</p>
                <p className='f21'>7623 Pécs, József Attila u. 20.</p>
            </div>
            <div className='c-content'>
                <form className='cc-form' action="https://formsubmit.co/4e1cf1ccc12c2dc5deae2f89c0db7701" method="POST">
                    <input type="hidden" name="_next" value="https://www.nagyjenoovoda.com/" />
                    <input type="hidden" name="_captcha" value="false" />
                    <input type="hidden" name="_subject" value="Új üzenet a weboldalról!" />
                    <input type="text" name="_honey" style={{display: "none"}} />
                    <div className='ccf-row'>
                        <input type="text" name="Név" id='nameInput' className='ccf-input' placeholder='Név*' required/>
                    </div>
                    <div className='ccf-row'>
                        <input type="email" name="Email" className='ccf-input' placeholder='Email*' required/>
                        <input type="text" name="Telefon" className='ccf-input' placeholder='Telefon' />
                    </div>
                    <textarea className='ccf-textarea' name="Üzenet" placeholder='Üzenet*' required></textarea>
                    <ArrowButton text="Küldés" icon={arrow}/>
                </form>
                <div id='schoolmap' className='cc-map'></div>
            </div>
            <div className='copyright' style={{display: "none"}}>
                {/* <p className='f16-p'>Minden jog fenntartva! © 2022</p> */}
                <div>
                    <p className='f16-p'>Design & fejlesztés:</p>
                    <a href='https://www.aracsidev.com/' className='f16-p'>@aracsidev</a>
                </div>
            </div>
        </div>
    )
}

export default Contact