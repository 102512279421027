import { database, storage } from '../../firebase';
import { ref, onValue, set } from 'firebase/database';
import { ref as stRef, uploadBytes, deleteObject } from 'firebase/storage';
import { useEffect, useState } from 'react';

const DashNews = () => {

    const dbRef = ref(database, '/new_news/');
    const [news, setNews] = useState([]);
    const [newsCard, setNewsCard] = useState({id: null, title: null, date: null, content: null});
    const [updatedTitle, setUpdatedTitle] = useState(null);
    const [updatedContent, setUpdatedContent] = useState(null);
    const [updatePopup, setUpdatePopup] = useState(0);

    const [newCardTitle, setNewCardTitle] = useState(null);
    const [newCardContent, setNewCardContent] = useState(null);
    const [addCardPopup, setAddCardPopup] = useState(0);

    useEffect(() => {
        readData();
    }, []);

    function readData() {
        onValue(dbRef, (snapshot) => {
            if (snapshot.exists) {
                let news_data = Object.values(snapshot.val());

                if (news_data.length > 1) {
                    for (let i = 0; i < news_data.length; i++) {
                        if (news_data[i].id === 0) {
                            news_data.splice(i, 1);
                        }
                    }
                }

                setNews(news_data);
            }
        });
    }

    function openUpdatePopup(id, title, date, content) {
        setNewsCard({
            id: id,
            title: title,
            date: date,
            content: content
        })

        setUpdatePopup(1);
    }

    function closeUpdatePopup() {
        setUpdatePopup(0);
    }

    function updateNewsCard() {

        const cardRef = ref(database, '/new_news/' + `news-${newsCard.id}`);

        set(cardRef, {
            id: newsCard.id,
            title: updatedTitle === null ? newsCard.title : updatedTitle,
            date: newsCard.date,
            content: updatedContent === null ? newsCard.content : updatedContent
        });

        closeUpdatePopup();
    }

    function intToDate(num) {

        if (num === 0) {
            num = Date.now();
        }
        
        let date = new Date(num);
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        const year = date.getFullYear();

        return `${year}.${month}.${day}.`;
    }
    
    function deleteCard() {

        const cardRef = ref(database, '/new_news/' + `news-${newsCard.id}`);

        if(window.confirm("Biztosan törölni szeretné ezt a hírt?")) {
            set(cardRef, null);
            closeUpdatePopup();
        }

    }

    function openAddCardPopup() {
        setAddCardPopup(1);
    }

    function closeAddCardPopup() {
        setAddCardPopup(0);
    }

    function addNewsCard() {
        let date = Date.now();

        const cardRef = ref(database, '/new_news/' + `news-${date}`);

        set(cardRef, {
            id: date,
            title: newCardTitle === null ? "" : newCardTitle,
            date: date,
            content: newCardContent === null ? "" : newCardContent
        });

        setNewCardTitle(null);
        setNewCardContent(null);

        closeAddCardPopup();
    }

    return (
        <>
            {
                updatePopup === 1 ? 
                <form className="upload-popup">
                    <p className="f16-p">Hír frissítése</p>
                    <div className="upload-container">
                        <input type="text" onChange={(e) => setUpdatedTitle(e.target.value)} className="textinput" defaultValue={newsCard.title} required/>
                        <p className='f14'>{intToDate(newsCard.date)}</p>
                        <textarea onChange={(e) => setUpdatedContent(e.target.value)} className="textinput" defaultValue={newsCard.content} required></textarea>
                    </div>
                    <div className="popup-btns">
                        <div className="popup-btn add" onClick={updateNewsCard}>
                            <p className="f16-p noselect">Frissítés</p>
                        </div>
                        <div className="popup-btn add" onClick={deleteCard}>
                            <p className="f16-p noselect">Törlés</p>
                        </div>
                        <div className="popup-btn del" onClick={closeUpdatePopup}>
                            <p className="f16-p noselect">Mégsem</p>
                        </div>
                    </div>
                </form>
            : ''}

            {
                addCardPopup === 1 ? 
                <form className="upload-popup">
                    <p className="f16-p">Hír hozzáadása</p>
                    <div className="upload-container">
                        <input type="text" onChange={(e) => setNewCardTitle(e.target.value)} className="textinput" required/>
                        <p className='f14'>{intToDate(Date.now())}</p>
                        <textarea onChange={(e) => setNewCardContent(e.target.value)} className="textinput" required></textarea>
                    </div>
                    <div className="popup-btns">
                        <div className="popup-btn add" onClick={addNewsCard}>
                            <p className="f16-p noselect">Hozzáadás</p>
                        </div>
                        <div className="popup-btn del" onClick={closeAddCardPopup}>
                            <p className="f16-p noselect">Mégsem</p>
                        </div>
                    </div>
                </form>
            : ''}

            <div className='dash-news'>
                <div className="dg-actions">
                    <div className="dga-button" onClick={openAddCardPopup}>
                        <p className="f14 noselect">Új hír hozzáadása</p>
                    </div>
                </div>
                <div className='news-cards'>
                    {
                        Object.values(news).map((news_card, index) => {
                            return ( index <= 3 ?
                                <div className='news-card' key={index} onClick={() => {
                                    if (news_card.id != 0) {
                                        openUpdatePopup(news_card.id, news_card.title, news_card.date, news_card.content);
                                    }
                                }}>
                                    <div className='news-card-title'>
                                        <p className='f16-p'>{news_card.title}</p>
                                        <p className='f14'>{intToDate(news_card.date)}</p>
                                    </div>
                                    <div className='news-card-content'>
                                        <p className='f16-p'>{news_card.content}</p>
                                    </div>
                                </div>
                            : "");
                        })
                    }
                </div>
            </div>
        </>
    );
}

export default DashNews;