import DashGroup from './DashGroup';

import { database, storage } from '../../firebase';
import { ref, onValue, set } from 'firebase/database';
import { ref as stRef, uploadBytes, deleteObject } from 'firebase/storage';
import { useEffect, useState } from 'react';


const DashGroups = () => {

    const [signupPopup, setSignupPopup] = useState(0);
    const [signupDoc, setSignupDoc] = useState(null);
    const [signupText, setSignupText] = useState(null);
    const [signupShow, setSignupShow] = useState(null);

    const [aboutPopup, setAboutPopup] = useState(0);
    const [aboutText, setAboutText] = useState(null);

    const [groupPopup, setGroupPopup] = useState(0);
    const [groupText, setGroupText] = useState(null);

    const [textData, setTextData] = useState(null);

    let [data, setData] = useState([]);
    let [signupData, setSignupData] = useState([]);

    const dbRef = ref(database, '/new_groups/');
    const dbSignupRef = ref(database, '/signup_document/');
    const dbTextRef = ref(database, '/text/');

    useEffect(() => readData(), []);

    function readData() {
        onValue(dbRef, (snapshot) => {
            if (snapshot.exists) {
                setData(Object.values(snapshot.val()));
            }
        });

        onValue(dbSignupRef, (snapshot) => {
            if (snapshot.exists) {
                setSignupData(snapshot.val());

                setSignupShow(snapshot.val().show);
                setSignupText(snapshot.val().text);
                setSignupDoc(snapshot.val().doc);
            }
        });

        onValue(dbTextRef, (snapshot) => {
            if (snapshot.exists) {
                setTextData(snapshot.val());

                setAboutText(snapshot.val().about_text);
                setGroupText(snapshot.val().group_text);
            }
        });
    }

    function openSignupPopup() {
        setSignupPopup(1);
    }

    function closeSignupPopup() {
        setSignupPopup(0);
    }

    function openAboutPopup() {
        setAboutPopup(1);
    }

    function closeAboutPopup() {
        setAboutPopup(0);
    }

    function openGroupPopup() {
        setGroupPopup(1);
    }

    function closeGroupPopup() {
        setGroupPopup(0);
    }

    function updateSignup() {
        if (signupDoc == signupData.doc) {
            set(dbSignupRef, {
                doc: signupData.doc,
                show: signupShow,
                text: signupText
            });
        } else {
            const docRef = stRef(storage, `/auth_signup_doc/${signupDoc.name}`);
            const oldDocRef = stRef(storage, `/auth_signup_doc/${signupData.doc}`);

            deleteObject(oldDocRef).then(() => {

            }).catch(err => {
                console.log(err);
            });

            uploadBytes(docRef, signupDoc).then((snapshot) => {
                console.log('Upload succesful!');
                set(dbSignupRef, {
                    doc: signupDoc.name,
                    show: signupShow,
                    text: signupText
                });
            });
        }

        closeSignupPopup();
    }

    function updateAboutText() {
        set(dbTextRef, {
            about_text: aboutText,
            group_text: textData.group_text
        });

        closeAboutPopup();
    }

    function updateGroupText() {
        set(dbTextRef, {
            about_text: textData.about_text,
            group_text: groupText
        });

        closeGroupPopup();
    }

    return (
        <>

            {
            
                signupPopup === 1 ? 
                <form className="upload-popup">
                    <p className="f16-p">Beiratkozási adatok frissítése</p>
                    <div className="upload-container">
                        <p className="f14">Új dokumentum feltöltése nélkül is frissítheti az adatokat.</p>
                        <input onChange={(e) => setSignupDoc(e.target.files[0])} className="signup fileinput" type="file"/>
                        <div className="checkboxinput">
                            <input onClick={(e) => setSignupShow(e.target.checked)} type="checkbox" defaultChecked={signupData.show ? true : false}/>
                            <p className="f14 noselect">Megjelenjen a főoldalon?</p>
                        </div>
                        <textarea onChange={(e) => setSignupText(e.target.value)} className="textinput" defaultValue={signupData.text} required></textarea>
                    </div>
                    <div className="popup-btns">
                        <div className="popup-btn add" onClick={updateSignup}>
                            <p className="f16-p noselect">Frissítés</p>
                        </div>
                        <div className="popup-btn del" onClick={closeSignupPopup}>
                            <p className="f16-p noselect">Mégsem</p>
                        </div>
                    </div>
                </form>
            
            : ''}

            {
                aboutPopup === 1 ? 
                <form className="upload-popup">
                    <p className="f16-p">Rólunk szöveg frissítése</p>
                    <div className="upload-container">
                        <textarea onChange={(e) => setAboutText(e.target.value)} className="textinput" defaultValue={textData.about_text} required></textarea>
                    </div>
                    <div className="popup-btns">
                        <div className="popup-btn add" onClick={updateAboutText}>
                            <p className="f16-p noselect">Frissítés</p>
                        </div>
                        <div className="popup-btn del" onClick={closeAboutPopup}>
                            <p className="f16-p noselect">Mégsem</p>
                        </div>
                    </div>
                </form>
            : ''}

            {
                groupPopup === 1 ? 
                <form className="upload-popup">
                    <p className="f16-p">Csoportjaink szöveg frissítése</p>
                    <div className="upload-container">
                        <textarea onChange={(e) => setGroupText(e.target.value)} className="textinput" defaultValue={textData.group_text} required></textarea>
                    </div>
                    <div className="popup-btns">
                        <div className="popup-btn add" onClick={updateGroupText}>
                            <p className="f16-p noselect">Frissítés</p>
                        </div>
                        <div className="popup-btn del" onClick={closeGroupPopup}>
                            <p className="f16-p noselect">Mégsem</p>
                        </div>
                    </div>
                </form>
            
            : ''}

            <div className="dashgroups">
                <div className="dg-actions">
                    <div className="dga-button" onClick={openSignupPopup}>
                        <p className="f14 noselect">BEIRATKOZÁSI ADATOK FRISSÍTÉSE</p>
                    </div>
                    <div className="dga-button" onClick={openAboutPopup}>
                        <p className="f14 noselect">RÓLUNK SZÖVEG FRISSÍTÉSE</p>
                    </div>
                    <div className="dga-button" onClick={openGroupPopup}>
                        <p className="f14 noselect">CSOPORTJAINK SZÖVEG FRISSÍTÉSE</p>
                    </div>
                </div>
                {
                    data.map((group, index) => {
                        return( <DashGroup key={index} index={index} group_data={group} /> )
                    })
                }
            </div>
        </>
    )
}

export default DashGroups;